import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useGetMe } from "../queries";
import { config } from "../Constants";
import { PrimaryButton } from "../components/Buttons";

function IndexPage() {
  const { data: user, isLoading, isError } = useGetMe();

  if (user !== undefined) {
    return <Navigate to="/chat" />;
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="flex flex-col justify-between h-full bg-white">
      <div>
        <img
          className="mx-auto my-12"
          src="/static/logo1.png"
          alt="Coach AI"
          width="75"
          height="75"
        />
        <div className="text-center mb-24">
          <div>
            Welcome to{" "}
            <a
              className="text-blue-500 hover:text-blue-600"
              href="https://personalcoach.app"
            >
              personalcoach.app
            </a>
          </div>
          <div>Lose weight and stay fit with your AI-powered coach</div>
        </div>

        <div className="m-auto w-44 mb-12">
          <PrimaryButton
            href={`${config.url.API_URL}/login`}
            label="Login"
            onClick={() => {}}
            submitting={false}
          />
        </div>
        <div className="text-center mt-auto mb-8">
          <div>Optimized for mobile</div>
        </div>
      </div>

      <div className="flex flex-row gap-3 justify-center text-sm my-4 ">
        <a
          className="text-blue-500 hover-text-blue-700"
          href="https://personalcoach.app"
        >
          Homepage
        </a>
        <a
          className="text-blue-500 hover-text-blue-700"
          href="https://personalcoach.app/#privacy"
        >
          Privacy notice
        </a>
        <a
          className="text-blue-500 hover-text-blue-700"
          href="https://personalcoach.app/#tos"
        >
          Terms of service
        </a>
      </div>
    </div>
  );
}

export default IndexPage;
