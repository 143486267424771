import { Message } from "../../types";
import { InfiniteData, QueryClient } from "@tanstack/react-query";
import parse from "html-react-parser";

export interface MessagePage {
  hasNext: boolean;
  messages: Message[];
}

export interface MessagePageIndex {
  begin?: number;
  end?: number;
}

function formatAndAddMessagesToTheFirstPage(
  data: InfiniteData<MessagePage>,
  messages: Message[],
  markAsNew: boolean,
): InfiniteData<MessagePage> {
  messages = messages.map((msg: any) => ({
    ...msg,
    formattedContent: msg.formattedContent
      ? parse(msg.formattedContent)
      : undefined,
  }));
  if (markAsNew) {
    messages = messages.map((msg) => ({ ...msg, isNew: true }));
  }
  if (data.pages.length === 0) {
    return {
      pages: [{ hasNext: true, messages: messages }],
      pageParams: [{}],
    };
  }
  const firstPage = data.pages[0];
  const firstPageParams = data.pageParams[0] as MessagePageIndex;
  const firstPageBegin = firstPageParams.begin
    ? firstPageParams.begin
    : firstPage.messages.length === 0
      ? 0
      : Math.min(...firstPage.messages.map((msg) => msg.id));
  return {
    pages: [
      {
        hasNext: firstPage.hasNext,
        messages: [...firstPage.messages, ...messages],
      },
      ...data.pages.slice(1),
    ],
    pageParams: [
      {
        ...firstPageParams,
        // Set the lower bound of the page explicitly, because its size grew.
        // Without setting the lower bound, the page would load only PAGE_SIZE latest
        // messages, thus older messages will no longer be fetched when new messages
        // are added to this page.
        begin: firstPageBegin,
      },
      ...data.pageParams.slice(1),
    ],
  };
}

export function markLastMessageFormAsSubmitted(queryClient: QueryClient) {
  queryClient.setQueryData(["messages"], (old: InfiniteData<MessagePage>) => {
    if (!old || old.pages.length === 0) {
      return old;
    }
    const lastPage = old.pages[old.pages.length - 1];
    const lastMessage = lastPage.messages[lastPage.messages.length - 1];
    if (lastMessage.messageType === "form") {
      lastMessage.submitted = true;
    }
  });
}

export function addNewMessagesToTheQueryData(
  queryClient: QueryClient,
  messages: Message[],
  markAsNew: boolean,
) {
  queryClient.setQueryData(["messages"], (old: InfiniteData<MessagePage>) => {
    if (!old) {
      return old;
    }
    return formatAndAddMessagesToTheFirstPage(old, messages, markAsNew);
  });
}
