import React, { useEffect, useState, ReactNode } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Modal } from "flowbite-react";
import { PrimaryButton } from "./Buttons";
import { InputField } from "./InputField";
import { User, Buddy } from "../types";
import { useAddBuddy, useEditBuddy, useDeleteBuddy } from "../queries";
import { isEmailValid } from "../utils";

function AddBuddyModal({
  open,
  setOpen,
  user,
  buddy,
  buddyIndex,
  onBuddyAdded,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  user: User;
  buddy: Buddy | undefined;
  buddyIndex: number;
  onBuddyAdded?: (buddy: Buddy) => void;
}) {
  const queryClient = useQueryClient();

  const [name, setName] = useState<string>(buddy ? buddy.name : "");
  const [email, setEmail] = useState<string>(buddy ? buddy.email : "");

  const [error, setError] = useState<string>("");

  const addBuddyMutation = useAddBuddy();
  const editBuddyMutation = useEditBuddy();
  const deleteBuddyMutation = useDeleteBuddy();

  const handleSubmit = () => {
    setError("");
    const nameTrimmed = name.trim();
    const emailTrimmed = email.trim();

    if (!nameTrimmed) {
      setError("Name field is mandatory");
      return;
    }
    if (!emailTrimmed) {
      setError("Email field is mandatory");
      return;
    }
    if (!isEmailValid(emailTrimmed)) {
      setError("Invalid email");
      return;
    }

    if (buddy) {
      editBuddyMutation.mutate({
        index: buddyIndex,
        name: nameTrimmed,
        email: emailTrimmed,
      });
    } else {
      addBuddyMutation.mutate({ name: nameTrimmed, email: emailTrimmed });
      if (onBuddyAdded)
        onBuddyAdded({ name: nameTrimmed, email: emailTrimmed });
    }
    setOpen(false);
  };

  const handleDelete = () => {
    if (!buddy) return;
    deleteBuddyMutation.mutate({ email: buddy.email });
    setOpen(false);
  };

  return (
    <Modal dismissible={true} show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <span className="font-semibold">
          {buddy ? "Edit buddy" : "Add a buddy"}
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          {!buddy && (
            <div>
              Add an accountability buddy, and we will automatically share your
              progress with them at the end of each week.
            </div>
          )}
          <InputField
            id="name"
            label="Name"
            value={name}
            setValue={setName}
            placeholder={""}
          />
          <InputField
            id="email"
            fieldType="email"
            label="Email"
            value={email}
            setValue={setEmail}
            placeholder={""}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full">
          {error && <div className="text-red-500 mb-2">{error}</div>}
          <PrimaryButton
            onClick={handleSubmit}
            label={buddy ? "Update" : "Add Buddy"}
            submitting={false}
          />
          {buddy && (
            <button
              onClick={handleDelete}
              className="mt-4 text-red-400 text-center"
            >
              Delete buddy
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default AddBuddyModal;
