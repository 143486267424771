import { User } from "../types";

export default function ProfileName({ user }: { user: User }) {
  return (
    <div className="flex flex-row gap-2 content-center mb-2">
      <img
        className="rounded-full"
        src={user.profileImg}
        width="55"
        height="55"
        loading="lazy"
        referrerPolicy="no-referrer"
      />
      <div className="flex flex-col justify-center font-semibold text-lg">
        {user.name}
      </div>
    </div>
  );
}
