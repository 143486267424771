export function Fire() {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50272 0.175124C7.5003 0.0982209 7.41601 0.0488203 7.35083 0.0897072C4.42288 1.92644 4.457 6.47206 4.49225 7.35138C4.49511 7.42272 4.42644 7.47182 4.36272 7.43961C4.0043 7.25844 3.06363 6.62308 3.00307 4.92422C3.00032 4.84721 2.91663 4.79869 2.85116 4.83934C1.14362 5.8995 0 7.84433 0 9.99999C0 13.3137 2.68629 16 6 16C9.31371 16 12 13.3137 12 9.99999C12 5.19474 7.62781 4.1544 7.50272 0.175124Z"
        fill="#FF8413"
      />
    </svg>
  );
}

export function Crown() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3316 1.31826C12.1337 1.225 11.9157 1.20048 11.7054 1.24784C11.4951 1.29519 11.302 1.41227 11.1508 1.58417L9.75 3.1686L7.26592 0.358778C7.06276 0.129053 6.78726 0 6.5 0C6.21274 0 5.93724 0.129053 5.73408 0.358778L3.25 3.1686L1.84925 1.58417C1.69774 1.41285 1.50473 1.29618 1.29462 1.24892C1.0845 1.20166 0.866716 1.22593 0.668791 1.31865C0.470866 1.41138 0.30169 1.5684 0.182648 1.76987C0.0636069 1.97133 4.5743e-05 2.2082 0 2.45052L0 8.93652C0.00086009 9.74871 0.286478 10.5274 0.794202 11.1017C1.30193 11.676 1.9903 11.999 2.70833 12H10.2917C11.0097 11.999 11.6981 11.676 12.2058 11.1017C12.7135 10.5274 12.9991 9.74871 13 8.93652V2.45052C13.0001 2.20818 12.9366 1.97126 12.8176 1.76972C12.6986 1.56819 12.5295 1.41108 12.3316 1.31826Z"
        fill="#FDD547"
      />
    </svg>
  );
}

export function ChevronLeft() {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.610352 10.0068C0.615885 9.81315 0.654622 9.63607 0.726562 9.47559C0.798503 9.3151 0.90918 9.16016 1.05859 9.01074L9.37598 0.958984C9.61393 0.721029 9.90723 0.602051 10.2559 0.602051C10.4883 0.602051 10.6986 0.657389 10.8867 0.768066C11.0804 0.878743 11.2326 1.02816 11.3433 1.21631C11.4595 1.40446 11.5176 1.61475 11.5176 1.84717C11.5176 2.19027 11.3875 2.49186 11.1274 2.75195L3.60693 9.99854L11.1274 17.2534C11.3875 17.519 11.5176 17.8206 11.5176 18.1582C11.5176 18.3962 11.4595 18.6092 11.3433 18.7974C11.2326 18.9855 11.0804 19.1349 10.8867 19.2456C10.6986 19.3618 10.4883 19.4199 10.2559 19.4199C9.90723 19.4199 9.61393 19.2982 9.37598 19.0547L1.05859 11.0029C0.903646 10.8535 0.790202 10.6986 0.718262 10.5381C0.646322 10.3721 0.610352 10.195 0.610352 10.0068Z"
        fill="#656565"
      />
    </svg>
  );
}

export function ChevronRight() {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.84961 6.00684C7.84961 6.15072 7.82194 6.28353 7.7666 6.40527C7.71126 6.52702 7.62549 6.646 7.50928 6.76221L2.53711 11.6846C2.35449 11.8672 2.13314 11.9585 1.87305 11.9585C1.60189 11.9585 1.37223 11.8672 1.18408 11.6846C0.995931 11.4964 0.901855 11.2695 0.901855 11.0039C0.901855 10.7383 1.00423 10.5031 1.20898 10.2983L5.56689 6.00684L1.20898 1.71533C1.00423 1.51058 0.901855 1.27539 0.901855 1.00977C0.901855 0.744141 0.995931 0.52002 1.18408 0.337402C1.37223 0.149251 1.60189 0.0551758 1.87305 0.0551758C2.13314 0.0551758 2.35449 0.146484 2.53711 0.329102L7.50928 5.25146C7.73617 5.46729 7.84961 5.71908 7.84961 6.00684Z"
        fill="#3C3C43"
        fillOpacity="0.3"
      />
    </svg>
  );
}

export function Streak15() {
  return (
    <svg
      className="inline w-[16px] h-[17px] mb-[2px]"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.75"
        cy="7.5"
        r="6.75"
        fill="url(#paint0_linear_2203_10015)"
      />
      <path
        d="M8.1911 7.07815C7.91496 7.07815 7.6911 6.85429 7.6911 6.57815V5.07678C7.6911 4.60999 7.10815 4.3978 6.80809 4.75537L4.48617 7.52232C4.21315 7.84766 4.44446 8.34372 4.86918 8.34372H5.73077C6.00692 8.34372 6.23077 8.56758 6.23077 8.84373V10.3451C6.23077 10.8119 6.81372 11.0241 7.11378 10.6665L9.4357 7.89956C9.70872 7.57421 9.47742 7.07815 9.05269 7.07815H8.1911Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2203_10015"
          x1="6.75"
          y1="0.75"
          x2="6.75"
          y2="14.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC634" />
          <stop offset="1" stopColor="#FF910F" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Streak16() {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.09218 6.73677C6.98172 6.73677 6.89218 6.64723 6.89218 6.53678V0.603835C6.89218 0.411246 6.64669 0.329936 6.53172 0.484446L0.594111 8.46447C0.34868 8.79433 0.584106 9.26295 0.995251 9.26295H4.10763C4.21808 9.26295 4.30762 9.35249 4.30762 9.46295V15.3959C4.30762 15.5885 4.55312 15.6698 4.66808 15.5153L10.6057 7.53525C10.8511 7.20539 10.6157 6.73677 10.2046 6.73677H7.09218Z"
        fill="#656565"
      />
    </svg>
  );
}

export function Streak26({ color }: { color: string }) {
  return (
    <svg
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3594 11.434C12.8177 11.3264 12.4658 10.8 12.5734 10.2583L14.2229 1.95507C14.4109 1.00868 13.2819 0.371191 12.5686 1.02096L0.481771 12.0312C-0.124822 12.5838 0.155532 13.5914 0.960342 13.7513L5.81626 14.716C6.35796 14.8236 6.70986 15.3499 6.60225 15.8916L4.95278 24.1949C4.76478 25.1413 5.89373 25.7788 6.60703 25.129L18.6939 14.1187C19.3005 13.5662 19.0201 12.5585 18.2153 12.3986L13.3594 11.434Z"
        fill={color}
      />
    </svg>
  );
}

export function Streak19() {
  return (
    <svg
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73076 8.00006C9.45462 8.00006 9.23076 7.7762 9.23076 7.50006V1.37366C9.23076 0.906862 8.64781 0.694674 8.34775 1.05225L0.689297 10.1785C0.416278 10.5039 0.647584 10.9999 1.07231 10.9999H5.26924C5.54538 10.9999 5.76924 11.2238 5.76924 11.4999V17.6263C5.76924 18.0931 6.35219 18.3053 6.65225 17.9478L14.3107 8.82147C14.5837 8.49612 14.3524 8.00006 13.9277 8.00006H9.73076Z"
        fill="white"
      />
    </svg>
  );
}

export function Streak41({
  color1,
  color2,
}: {
  color1: string;
  color2: string;
}) {
  return (
    <svg
      width="31"
      height="41"
      viewBox="0 0 31 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={color1} />
          <stop offset="100%" stopColor={color2} />
        </linearGradient>
      </defs>
      <path
        d="M20.8077 16.9475C19.9792 16.9475 19.3077 16.2759 19.3077 15.4475V2.36422C19.3077 0.936795 17.5022 0.316244 16.6246 1.44205L0.888061 21.6303C0.120221 22.6154 0.822146 24.0525 2.07111 24.0525H10.1923C11.0208 24.0525 11.6923 24.7241 11.6923 25.5525V38.6358C11.6923 40.0632 13.4978 40.6838 14.3754 39.5579L30.1119 19.3697C30.8798 18.3846 30.1779 16.9475 28.9289 16.9475H20.8077Z"
        fill="url(#Gradient1)"
      />
    </svg>
  );
}

export function SubscriptionLock15({ color }: { color: string }) {
  return (
    <svg
      className="inline w-[11px] h-[15px] mb-[3px]"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.35 5.5336V4.75039C9.35 2.6241 7.62631 0.900391 5.5 0.900391C3.37369 0.900391 1.65 2.6241 1.65 4.75039V5.5336C0.649018 5.97046 0.00144375 6.95825 0 8.05039V11.3504C0.00180469 12.8684 1.23196 14.0986 2.74999 14.1004H8.24999C9.76801 14.0986 10.9982 12.8684 11 11.3504V8.05039C10.9986 6.95825 10.351 5.97046 9.35 5.5336ZM6.04999 10.2504C6.04999 10.5542 5.80375 10.8004 5.5 10.8004C5.19624 10.8004 4.95001 10.5542 4.95001 10.2504V9.1504C4.95001 8.84665 5.19624 8.60041 5.5 8.60041C5.80375 8.60041 6.04999 8.84665 6.04999 9.1504V10.2504ZM8.25001 5.30041H2.74999V4.75041C2.74999 3.23164 3.9812 2.0004 5.5 2.0004C7.0188 2.0004 8.25001 3.23161 8.25001 4.75041V5.30041Z"
        fill={color}
      />
    </svg>
  );
}

export function CloseButtonCrossIcon() {
  return (
    <svg
      width="20"
      height="20"
      stroke="#656565"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  );
}

export function TimerIcon15() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.5C13 10.8139 10.3139 13.5 7 13.5C3.68605 13.5 1 10.8139 1 7.5C1 4.18605 3.68605 1.5 7 1.5C10.3139 1.5 13 4.18605 13 7.5Z"
        stroke="#656565"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.22666 9.40945L6.78125 7.95064V4.80664"
        stroke="#656565"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Spinner({ fillColor }: { fillColor: string }) {
  return (
    <svg
      aria-hidden="true"
      role="status"
      className="inline w-4 h-4 me-3 text-white animate-spin"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill={fillColor}
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RoundCheckboxUnmarked({ color }: { color: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline w-6 h-6"
      viewBox="-3 -3 20 20"
    >
      <circle
        cx="5.71"
        cy="6.27"
        r="6"
        style={{
          fill: "#fff",
          fillOpacity: "0",
          stroke: color,
          strokeWidth: "1.5px",
        }}
      />
    </svg>
  );
}

export function RoundCheckboxMarked() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline w-6 h-6"
      viewBox="-3 -3 20 20"
    >
      {/* <circle cx="5.71" cy="6.27" r="7" style={{fill: '#fff', strokeWidth: '0px'}}/> */}
      <polyline
        points="2 5.34 5.43 9.34 11.5 0.5"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6px",
        }}
      />
      <polyline
        points="2 5.34 5.43 9.34 11.5 0.5"
        style={{
          fill: "none",
          stroke: "#40c300",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "2.5px",
        }}
      />
    </svg>
  );
}

export function Checkmark8() {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.44043 4L4.39409 7L10.2989 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
