import { Modal } from "flowbite-react";
import ProfileName from "./ProfileName";
import { User } from "../types";
import { useLocation, useNavigate } from "react-router-dom";
import { Crown, Fire, Streak16, ChevronRight, Streak19 } from "./Icons";
import { ProgressBar } from "./ProgressBar";
import {
  weekDoneTaskCount,
  currentWeekStreak,
  weekStreakRequiredTaskCount,
  streakProgressTitleAndText,
  isThisWeekStreakRequirementMet,
} from "../week-progress-util";

function WeekStreakDonutProgressBar({ user }: { user: User }) {
  const requiredCount = weekStreakRequiredTaskCount(user);
  const doneCount = weekDoneTaskCount(user);
  const completionAngle =
    doneCount >= requiredCount
      ? 360
      : Math.max(Math.round((doneCount / requiredCount) * 360), 10);

  const segmentStyle = {
    background: `conic-gradient(
      transparent 0deg,
      transparent ${completionAngle}deg,
      #eaaae1 ${completionAngle}deg,
      #eaaae1 360deg
    )`,
  };

  const streak = user.previousWeeksStreak + 1;
  const isStreakCompleted = isThisWeekStreakRequirementMet(user);

  return (
    <div className="w-[90px] h-[90px] rounded-full bg-gradient-to-b from-[#B449B6] to-[#DE50B6] relative">
      <div
        className="w-[90px] h-[90px] rounded-full p-[13px]"
        style={segmentStyle}
      >
        <div className="flex flex-col items-center justify-center w-full h-full rounded-full bg-gradient-to-r from-[#D789E5] to-[#D777C4] text-white">
          {!isStreakCompleted && (
            <div className="text-center mt-[2px]">
              <p className="text-sm font-semibold leading-none">week</p>
              <p className="font-bold text-3xl leading-none">{streak}</p>
            </div>
          )}
          {isStreakCompleted && (
            <div className="text-center">
              <div>
                <p className="font-bold text-3xl leading-none">{streak}</p>
                <p className="text-sm font-semibold leading-none">weeks</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="absolute left-[29px] top-[-10px] rounded-full w-[32px] h-[32px] bg-[#FFBC0F] flex items-center justify-center">
        <Streak19 />
      </div>
    </div>
  );
}

export function StreakSection({ user }: { user: User }) {
  const textAndTitle = streakProgressTitleAndText(user, false);
  const title = textAndTitle[0];
  const text = textAndTitle[1];

  return (
    <div className="rounded-2xl bg-gradient-to-r from-[#CD97F9] via-[#EA7CC2] to-[#FF779B] py-6 pl-5 pr-4">
      <div className="flex flex-row justify-start gap-6">
        <WeekStreakDonutProgressBar user={user} />
        <div className="flex flex-col justify-center gap-0">
          <div className="text-[22px] font-semibold text-white">{title}</div>
          <div className="text-sm font-semibold text-white text-xs">{text}</div>
        </div>
      </div>
    </div>
  );
}

function TaskRedirectButton({
  onTaskClick,
  taskName,
}: {
  onTaskClick: () => void;
  taskName: string;
}) {
  return (
    <button type="button" onClick={onTaskClick} className="mt-6">
      <div className="flex items-center gap-4">
        <div className="text-gray-700">{taskName}</div>
        <div className="flex-1" />
        <div className="py-1 px-2 bg-[#FFF1BE] rounded-full">
          <div className="flex flex-row gap-1 items-center">
            <span className="text-sm font-semibold text-[#AD611B]">+1</span>
            <Fire />
          </div>
        </div>
        <ChevronRight />
      </div>
    </button>
  );
}

export function ProgressModal({
  user,
  show,
  onClose,
}: {
  user: User;
  show: boolean;
  onClose: () => void;
}) {
  const firesToNextLevel = Math.max(
    user.nextLevelFireRequirement - user.fires,
    0,
  );
  const fireRangeWidth = Math.max(
    user.nextLevelFireRequirement - user.currentLevelFireRequirement,
    0,
  );
  const currentLevelFireCollected = Math.max(
    Math.min(user.fires - user.currentLevelFireRequirement, fireRangeWidth),
    0,
  );
  const levelProgressPercentage = Math.round(
    (currentLevelFireCollected / fireRangeWidth) * 100,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const onExerciseTaskClick = () => {
    if (location.pathname === "/plan") {
      onClose();
    } else {
      onClose();
      navigate("/plan");
    }
  };

  const onNutritionTaskClick = () => {
    if (location.pathname === "/food") {
      onClose();
    } else {
      onClose();
      navigate("/food");
    }
  };

  let hasUnreadNutritionArticle = user.articles.some(
    (article) =>
      !article.state.wasReadAt && article.metadata.tags.includes("nutrition"),
  );

  let hasUnreadExerciseArticle = user.articles.some(
    (article) =>
      !article.state.wasReadAt && !article.metadata.tags.includes("nutrition"),
  );

  return (
    <Modal show={show} onClose={onClose} dismissible={true}>
      <Modal.Header>
        <span className="font-semibold">My Progress</span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-0">
          <div className="flex flex-row justify-between">
            <ProfileName user={user} />
            <div className="flex flex-col justify-center gap-0">
              <div className="flex flex-row justify-end gap-1 w-full items-center">
                <Crown />
                <div className="font-semibold text-gray-600 text-right">
                  Level {user.level}
                </div>
              </div>
              <div className="font-semibold text-gray-600 w-full text-right">
                {user.levelTitle}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <ProgressBar
              percentage={levelProgressPercentage}
              backgroundColor="bg-black bg-opacity-20"
              progressColor="bg-gradient-to-r from-[#CD97F9] via-[#EA7CC2] to-[#FF779B]"
            />
          </div>

          <div className="flex flex-row justify-between items-center mt-1">
            <div className="text-sm font-semibold text-gray-600">
              {firesToNextLevel !== 1 && (
                <span>{firesToNextLevel} fires to the next level</span>
              )}
              {firesToNextLevel === 1 && (
                <span>{firesToNextLevel} fire to the next level</span>
              )}
            </div>
            <div className="flex flex-row gap-1 items-center">
              <Fire />
              <div className="text-lg font-semibold text-gray-600">
                {user.fires}
              </div>
            </div>
          </div>

          <TaskRedirectButton
            onTaskClick={onExerciseTaskClick}
            taskName="Complete daily exercise"
          />
          {hasUnreadExerciseArticle && (
            <TaskRedirectButton
              onTaskClick={onExerciseTaskClick}
              taskName="Read exercise article"
            />
          )}
          {hasUnreadNutritionArticle && (
            <TaskRedirectButton
              onTaskClick={onNutritionTaskClick}
              taskName="Read nutrition article"
            />
          )}

          <div className="flex flex-row justify-between items-center mt-10 mb-2">
            <div className="text-lg font-semibold text-gray-800">My Streak</div>
            <div className="flex flex-row gap-1 items-center">
              <Streak16 />
              <div className="text-small text-gray-600 font-semibold">
                {currentWeekStreak(user)} weeks
              </div>
            </div>
          </div>
          <StreakSection user={user} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
