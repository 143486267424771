import { useState, useRef, useEffect } from "react";
import { useGetMe, useCompleteDailyPlanMutation } from "../queries";
import { ExplotionAnimation } from "./ExplosionAnimation";
import {
  Spinner,
  RoundCheckboxMarked,
  RoundCheckboxUnmarked,
  Checkmark8,
} from "./Icons";
import { PaywallInlineIcon } from "./Buttons";
import { PaywallButton } from "./PaywallModal";

enum DoneStatusButtonState {
  Loading,
  MarkAsDone,
  Done,
  Error,
}

export enum DoneStatusButtonStyle {
  WeeklyPlan,
  DailyTaskChatMessage,
  CheckboxOnly,
}

function WeeklyPlanDoneStatusButton({
  state,
  onClick,
  requiresSubscription,
}: {
  state: DoneStatusButtonState;
  onClick: (newDoneStatus: boolean) => void;
  requiresSubscription: boolean;
}) {
  return (
    <div className="h-[24px]">
      {state === DoneStatusButtonState.Loading && (
        <Spinner fillColor="#666f8a" />
      )}
      {state === DoneStatusButtonState.Done && (
        <button
          type="button"
          onClick={() => onClick(false)}
          className="text-black font-bold"
        >
          Done <RoundCheckboxMarked />
        </button>
      )}
      {state === DoneStatusButtonState.MarkAsDone && (
        <PaywallButton
          enablePaywall={requiresSubscription}
          onClick={() => onClick(true)}
        >
          <div className="text-blue-600">
            {requiresSubscription && (
              <PaywallInlineIcon color="#1E88E5" suffix=" " />
            )}
            Mark as done ✔️
          </div>
        </PaywallButton>
      )}
      {/* TODO: Debug only, remove before release (?) */}
      {state === DoneStatusButtonState.Error && <div>Error</div>}
    </div>
  );
}

function ChatMessageDoneStatusButton({
  state,
  onClick,
  requiresSubscription,
}: {
  state: DoneStatusButtonState;
  onClick: (newDoneStatus: boolean) => void;
  requiresSubscription: boolean;
}) {
  return (
    <div>
      {state === DoneStatusButtonState.Loading && (
        <div className="h-[36px] min-w-[170px] bg-blue-500 rounded-lg flex justify-center items-center">
          <div className="ml-2 mb-1">
            <Spinner fillColor="#E5E7EB" />
          </div>
        </div>
      )}
      {state === DoneStatusButtonState.Done && (
        <button
          type="button"
          onClick={() => onClick(false)}
          className="text-black font-bold h-[36px] min-w-[170px] bg-white rounded-lg"
        >
          <div className="ml-4 text-right">
            Done <RoundCheckboxMarked />
          </div>
        </button>
      )}
      {state === DoneStatusButtonState.MarkAsDone && (
        <PaywallButton
          enablePaywall={requiresSubscription}
          onClick={() => onClick(true)}
        >
          <div className="text-white h-[36px] min-w-[170px] bg-blue-500 rounded-lg flex justify-center items-center">
            <div className="ml-2">
              {requiresSubscription && (
                <PaywallInlineIcon suffix=" " color="white" />
              )}
              Mark as done <RoundCheckboxUnmarked color="#ffffff" />
            </div>
          </div>
        </PaywallButton>
      )}
      {/* TODO: Debug only, remove before release (?) */}
      {state === DoneStatusButtonState.Error && <div>Error</div>}
    </div>
  );
}

function CheckboxOnlyDoneStatusButton({
  state,
  onClick,
  requiresSubscription,
}: {
  state: DoneStatusButtonState;
  onClick: (newDoneStatus: boolean) => void;
  requiresSubscription: boolean;
}) {
  return (
    <div className="relative h-[24px] w-[24px]">
      {state === DoneStatusButtonState.Loading && (
        <div className="absolute h-[24px] w-[24px] flex justify-center items-center m-[16px] left-[-16px] top-[-16px]">
          <div className="ml-3">
            <Spinner fillColor="#7393B3" />
          </div>
        </div>
      )}
      {state === DoneStatusButtonState.Done && (
        <button
          type="button"
          onClick={() => onClick(false)}
          className="absolute h-[24px] w-[24px] rounded-full bg-blue-500 m-[16px] left-[-16px] top-[-16px] flex justify-center items-center"
        >
          <Checkmark8 />
        </button>
      )}
      {state === DoneStatusButtonState.MarkAsDone && (
        <PaywallButton
          enablePaywall={requiresSubscription}
          onClick={() => onClick(true)}
        >
          <div className="absolute h-[24px] w-[24px] rounded-full border border-slate-400 m-[16px] left-[-16px] top-[-16px] flex justify-center items-center">
            {requiresSubscription && <PaywallInlineIcon color="#94A3B8" />}
          </div>
        </PaywallButton>
      )}
    </div>
  );
}

export function DailyPlanDoneStatusButton({
  day,
  week,
  style,
  requiresSubscription,
}: {
  day: string;
  week: string | null;
  style: DoneStatusButtonStyle;
  requiresSubscription: boolean;
}) {
  const { data: me, isSuccess, isError } = useGetMe();
  const audioRef = useRef<HTMLAudioElement>(null);
  const buttonClicked = useRef(false);
  const mutation = useCompleteDailyPlanMutation();
  const isDone = (me?.weeklyPlan?.days as any)?.[day]?.done;
  const [drawAnimation, setDrawAnimation] = useState(false);

  let state = DoneStatusButtonState.Loading;
  if (!mutation.isPending && isSuccess && isDone) {
    state = DoneStatusButtonState.Done;
  } else if (!mutation.isPending && isSuccess && !isDone) {
    state = DoneStatusButtonState.MarkAsDone;
  } else if (!mutation.isPending && isError) {
    state = DoneStatusButtonState.Error;
  }

  useEffect(() => {
    if (
      buttonClicked.current &&
      mutation.isSuccess &&
      isDone &&
      audioRef.current
    ) {
      audioRef.current.play();
      setDrawAnimation(true);
      buttonClicked.current = false;
    }
  }, [mutation.isSuccess]);

  const onClick = (newDoneStatus: boolean) => {
    mutation.mutate({ day, done: newDoneStatus });
    buttonClicked.current = true;
  };

  const animationPosition =
    style === DoneStatusButtonStyle.DailyTaskChatMessage
      ? "top-1/2 left-3/4"
      : "top-1/2 left-1/2";

  return (
    <div className="relative">
      {style === DoneStatusButtonStyle.WeeklyPlan && (
        <WeeklyPlanDoneStatusButton
          state={state}
          onClick={onClick}
          requiresSubscription={requiresSubscription}
        />
      )}
      {style === DoneStatusButtonStyle.DailyTaskChatMessage && (
        <ChatMessageDoneStatusButton
          state={state}
          onClick={onClick}
          requiresSubscription={requiresSubscription}
        />
      )}
      {style === DoneStatusButtonStyle.CheckboxOnly && (
        <CheckboxOnlyDoneStatusButton
          state={state}
          onClick={onClick}
          requiresSubscription={requiresSubscription}
        />
      )}
      {drawAnimation && (
        <ExplotionAnimation
          position={animationPosition}
          onEnd={() => setDrawAnimation(false)}
        />
      )}
      <audio ref={audioRef} src="/static/on-done.mp3" />
    </div>
  );
}
