function OnSignupPage() {
  return (
    <div className="flex flex-col items-center h-full justify-center">
      <div className="w-[70%] max-w-[400px]">
        <img src="/static/coach-signup.png" />
      </div>

      <div className="font-semibold text-[28px] mt-[50px]">
        I’m your AI Coach
      </div>
      <div className="w-[90%] max-w-[260px] text-sm text-gray-600 text-center mt-5">
        <p>
          I’m here to help you reach your goals and become to best version of
          yourself.
        </p>
        <p className="mt-4">
          Let's make a commitment to your future self.{" "}
          <strong>Promise to give your best effort and stay dedicated.</strong>
        </p>
      </div>

      <a
        className="flex items-center justify-center h-[44px] w-[90%] max-w-[400px] bg-[#4989E8] text-white rounded-lg mt-[80px]"
        href="/chat"
      >
        I promise!
      </a>
    </div>
  );
}

export default OnSignupPage;
