export const COMPONENTS = {
  img: ({ ...props }) => <img className="block rounded-lg" {...props} />,
  h1: ({ ...props }) => (
    <h1
      className="mt-6 first:mt-0 mb-6 text-gray-800 font-semibold text-xl"
      {...props}
    />
  ),
  h2: ({ ...props }) => (
    <h2
      className="mt-6 first:mt-0 mb-3 text-gray-800 font-semibold text-lg"
      {...props}
    />
  ),
  h3: ({ ...props }) => (
    <h3 className="mt-6 first:mt-0 mb-1 font-semibold" {...props} />
  ),
  p: ({ ...props }) => <p className="text-sm" {...props} />,
  strong: ({ ...props }) => <strong className="font-semibold" {...props} />,
  ul: ({ ...props }) => <ul className="list-disc text-sm" {...props} />,
  a: ({ ...props }) => (
    <a className="text-blue-500 underline" target="_blank" {...props} />
  ),
};
