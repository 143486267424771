import { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { useSearchParams } from "react-router-dom";
import { useSendMessageMutation } from "../../queries";

function MessagingBlock({
  disabled,
  hidden,
  conversationId,
  callback,
  setSendingMessage,
}: {
  disabled: boolean;
  hidden: boolean;
  conversationId: number;
  callback?: string;
  setSendingMessage: (v: boolean) => void;
}) {
  const [message, setMessage] = useState("");
  const [inputExpanded, setInputExpanded] = useState(false);
  const textareaRef = useRef<any>(null);

  let [searchParams, setSearchParams] = useSearchParams();
  const isEdittingPlan = useRef(searchParams.get("edit_plan") === "1");
  const sendMessageMutation = useSendMessageMutation();

  useEffect(() => {
    setSendingMessage(sendMessageMutation.isPending);
  }, [sendMessageMutation.isPending]);

  function sendMessage() {
    if (disabled) return;
    if (message.trim() === "") return;

    sendMessageMutation.mutate({
      conversationId: conversationId,
      content: message,
      callback: callback,
    });
    setMessage("");
  }

  function onKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Enter" && e.ctrlKey) {
      e.preventDefault();
      sendMessage();
    }
  }

  useEffect(() => {
    if (!textareaRef.current) return;

    textareaRef.current.style.height = "0px";
    let scrollHeight = textareaRef.current.scrollHeight;
    if (scrollHeight > 350) scrollHeight = 350;
    textareaRef.current.style.height = scrollHeight + "px";

    if (textareaRef.current && textareaRef.current.scrollHeight > 50)
      setInputExpanded(true);
    else setInputExpanded(false);

    if (isEdittingPlan.current) {
      textareaRef.current.focus();
      const len = textareaRef.current.value.length;
      textareaRef.current.selectionStart = len;
      textareaRef.current.selectionEnd = len;
      isEdittingPlan.current = false;
      if (searchParams.has("edit_plan")) {
        searchParams.delete("edit_plan");
        setSearchParams(searchParams);
      }
    }
  }, [message, searchParams]);

  if (isEdittingPlan.current && message === "") {
    setMessage("Hey! Here are some changes I'd like to make to my plan: ");
  }

  if (hidden) return <></>;

  return (
    <div className="flex py-4 relative">
      <textarea
        ref={textareaRef}
        name="message-to-coach"
        autoComplete="off"
        className={
          "w-full resize-none border-0 outline-0 bg-indigo-50 focus:outline-1 focus:outline-indigo-300 text-black p-3 pe-8 m-auto overflow-hidden mx-6 " +
          (inputExpanded ? "rounded-2xl" : "rounded-full")
        }
        style={{
          fontFamily: "Inter",
        }}
        placeholder="Send a message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={onKeyDown}
        rows={1}
      ></textarea>
      <button
        type="button"
        onClick={sendMessage}
        className="absolute right-[30px] w-[32px] bottom-[24px] h-[32px] flex flex-col justify-center items-center cursor-pointer"
      >
        <FontAwesomeIcon icon={faPaperPlane} className="text-lg" />
      </button>
    </div>
  );
}

export default MessagingBlock;
