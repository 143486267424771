import { useRef, useEffect } from "react";
import {
  useGetMe,
  useMarkArticleAsReadMutation,
  useGetArticleMarkdownContent,
} from "../queries";
import Markdown, { defaultUrlTransform } from "react-markdown";
import { config } from "../Constants";
import { Article, hasUserReadArticle } from "../types";
import { CloseButtonCrossIcon, Checkmark8 } from "./Icons";
import { useState } from "react";
import { PrimaryButton, PaywallInlineIcon } from "./Buttons";
import { PaywallButton } from "./PaywallModal";
import { Modal } from "flowbite-react";
import { COMPONENTS } from "../markdown";

export function ArticleRenderer({ article }: { article: Article }) {
  const query = useGetArticleMarkdownContent(article.metadata.id);

  if (query.isError) {
    return <div>Error loading article...</div>;
  }

  if (query.isLoading || !query.data) {
    return <div>Loading...</div>;
  }

  const transformImageUri = (uri: string, key: string, element: any) => {
    if (element.tagName === "img") {
      return defaultUrlTransform(
        `${config.url.API_URL}/static/library/${article.metadata.id}/${uri}`,
      );
    } else {
      return defaultUrlTransform(uri);
    }
  };

  return (
    <Markdown urlTransform={transformImageUri} components={COMPONENTS}>
      {query.data}
    </Markdown>
  );
}

export function ArticleModal({
  article,
  open,
  onClose,
}: {
  article: Article;
  open: boolean;
  onClose: (completedReadingForTheFirstTime: boolean) => void;
}) {
  const me = useGetMe();
  const markDoneMutation = useMarkArticleAsReadMutation();
  const userHasReadArticle = hasUserReadArticle(article.state);
  const buttonLabel = userHasReadArticle ? "Done" : "Mark as Done";
  const donePressed = useRef(false);

  const onDoneClick = () => {
    if (me.isPending || markDoneMutation.isPending) {
      return;
    }

    if (userHasReadArticle) {
      onClose(false);
    } else {
      markDoneMutation.mutate({ articleId: article.metadata.id });
      donePressed.current = true;
    }
  };

  useEffect(() => {
    if (donePressed.current && markDoneMutation.isSuccess) {
      donePressed.current = false;
      onClose(true);
    }
  }, [markDoneMutation.isSuccess]);

  return (
    <Modal
      dismissible={true}
      show={open}
      onClose={() => onClose(false)}
      position="bottom-center"
    >
      <Modal.Body>
        <div className="absolute top-0 left-0 flex justify-end w-full bg-white rounded-t-lg">
          <button onClick={() => onClose(false)} className="p-4">
            <CloseButtonCrossIcon />
          </button>
        </div>
        <div className="flex flex-col w-full">
          <div className="h-[32px]" /> {/* Close button spacer */}
          <ArticleRenderer article={article} />
          <div className="mt-12">
            <PrimaryButton
              onClick={onDoneClick}
              label={buttonLabel}
              submitting={me.isPending || markDoneMutation.isPending}
            />
            {!userHasReadArticle && (
              <PrimaryButton
                onClick={() => onClose(false)}
                label={"✕ Close"}
                submitting={false}
                variant="tertiary"
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function LibraryArticleButton({ article }: { article: Article }) {
  const [showModal, setShowModal] = useState(false);

  const onModalClose = (completedReading: boolean) => {
    setShowModal(false);
    // TODO: show animation and play sound here
  };

  const durationMinutes = article.metadata.duration
    ? Math.floor(article.metadata.duration / 60)
    : 0;

  return (
    <>
      {showModal && (
        <ArticleModal
          article={article}
          open={showModal}
          onClose={onModalClose}
        />
      )}
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="text-left"
      >
        <div className="w-32 flex flex-col h-full rounded-xl gap-2">
          <img
            className="rounded-xl w-full shadow-sm shadow-slate-200"
            src={`${config.url.API_URL}/static/library/${article.metadata.id}/thumbnail.webp`}
          />
          <div className="text-gray-800 font-semibold text-sm text-center">
            {article.metadata.title}
          </div>
        </div>
      </button>
    </>
  );
}

function ArticleButton({ article }: { article: Article }) {
  const [showModal, setShowModal] = useState(false);

  const onModalClose = (completedReading: boolean) => {
    setShowModal(false);
    // TODO: show animation and play sound here
  };

  const durationMinutes = article.metadata.duration
    ? Math.floor(article.metadata.duration / 60)
    : 0;

  const isFreeArticle = article.metadata.id == "setting-realistic-goals";

  return (
    <div>
      <ArticleModal article={article} open={showModal} onClose={onModalClose} />
      <PaywallButton
        enablePaywall={!isFreeArticle}
        onClick={() => setShowModal(true)}
      >
        <div className="flex flex-row items-center gap-4 text-left">
          <div className="basis-16 grow-0 shrink-0">
            <img
              className="w-full rounded-lg"
              src={`${config.url.API_URL}/static/library/${article.metadata.id}/thumbnail.webp`}
            />
          </div>
          <div className="grow">
            <div className="font-semibold text-slate-700 mt-2">
              {article.metadata.title}
            </div>
            <div className="text-slate-600 text-sm mt-1">
              {article.metadata.description +
                (durationMinutes ? ` (${durationMinutes} min)` : "")}
            </div>
            <div className="text-blue-500 font-semibold">
              <PaywallInlineIcon
                color="#3F83F8"
                suffix=" "
                disabled={isFreeArticle}
              />
              Read now
            </div>
          </div>
        </div>
      </PaywallButton>
    </div>
  );
}

export function ArticleButtonWithBorderAndCheckmark({
  article,
}: {
  article: Article;
}) {
  const isRead = article.state.wasReadAt !== null;
  return (
    <div className="rounded-xl p-2 border border-gray-400 flex justify-between">
      <ArticleButton article={article} />
      <div>
        {isRead && (
          <div className="w-[24px] h-[24px] mt-1 rounded-full bg-blue-500 flex justify-center items-center">
            <Checkmark8 />
          </div>
        )}
        {!isRead && (
          <div className="w-[24px] h-[24px] mt-1 rounded-full border border-gray-400" />
        )}
      </div>
    </div>
  );
}
