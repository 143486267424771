import { useState } from "react";
import { Modal } from "flowbite-react";
import {
  useGetNutritionRecommendationQuery,
  useGetRecipeDetailsQuery,
  useRegenerateMenuMutation,
} from "../../queries";
import { TimerIcon15 } from "../../components/Icons";
import { config } from "../../Constants";
import { ArticleButtonWithBorderAndCheckmark } from "../../components/ArticleComponent";
import { User, Recipe } from "../../types";
import { PaywallInlineIcon } from "../../components/Buttons";
import { PaywallButton } from "../../components/PaywallModal";

function NutrientComponent({ name, amount }: { name: string; amount: string }) {
  return (
    <div className="flex flex-col items-center">
      <div className="font-semibold">{amount}</div>
      <div className="font-semibold text-gray-600 text-xs">{name}</div>
    </div>
  );
}

function RecipeDetails({ recipe }: { recipe: Recipe }) {
  const nutrients = recipe.nutritionContentPer100g;

  return (
    <div>
      {recipe.imageUrl && (
        <div className="flex justify-center">
          <div className="w-full max-w-[400px] aspect-[14/9] rounded-xl overflow-hidden relative">
            <img
              src={recipe.imageUrl}
              alt={recipe.name}
              loading="lazy"
              className="w-full h-auto object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </div>
      )}
      {recipe.preparationTime && recipe.preparationTime > 0 && (
        <div className="flex justify-begin gap-1 items-center mt-4">
          <TimerIcon15 />
          <div className="text-sm text-gray-600 font-semibold">
            Cooking time: {recipe.preparationTime} min
          </div>
        </div>
      )}

      <h4 className="text-lg font-semibold mt-8">Nutrients per 100g</h4>
      <div className="bg-gray-100 rounded-xl p-4 mt-4">
        <div className="flex flex-wrap justify-between">
          {recipe.caloriesPer100g && (
            <NutrientComponent
              name="kcal"
              amount={`${Math.floor(recipe.caloriesPer100g)}`}
            />
          )}
          {nutrients?.fatTotal && (
            <NutrientComponent
              name="Fat"
              amount={`${Math.round(nutrients.fatTotal)}g`}
            />
          )}
          {nutrients?.protein && (
            <NutrientComponent
              name="Protein"
              amount={`${Math.round(nutrients.protein)}g`}
            />
          )}
          {nutrients?.carbsTotal && (
            <NutrientComponent
              name="Carbs"
              amount={`${Math.round(nutrients.carbsTotal)}g`}
            />
          )}
          {nutrients?.sugars && (
            <NutrientComponent
              name="Sugars"
              amount={`${Math.round(nutrients.sugars)}g`}
            />
          )}
          {nutrients?.fiber && (
            <NutrientComponent
              name="Fiber"
              amount={`${Math.round(nutrients.fiber)}g`}
            />
          )}
        </div>
      </div>

      {recipe.ingredients && (
        <div>
          <h4 className="text-lg font-semibold mt-8">Ingredients</h4>
          <div className="mt-4 bg-gray-100 rounded-xl px-4 py-3 flex flex-col">
            {recipe.ingredients.map((ingredient, index) => (
              <div key={index}>
                <div>{ingredient}</div>
                {index < recipe.ingredients.length - 1 && (
                  <div className="border-b border-gray-300 w-full my-2"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-8 mb-4">
        <a href={recipe.url} target="_blank" rel="noreferrer">
          <div className="flex items-center justify-center bg-[#4989E8] h-[44px] w-full w-max-[400px] text-white rounded-lg">
            ⬈ Check out Recipe
          </div>
        </a>
      </div>
    </div>
  );
}

function RecipeDetailsModal({
  recipeId,
  recipeName,
  onClose,
}: {
  recipeId: string;
  recipeName: string;
  onClose: () => void;
}) {
  const query = useGetRecipeDetailsQuery(recipeId);

  return (
    <Modal show={true} onClose={onClose} dismissible={true}>
      <Modal.Header>
        <span className="font-semibold">{recipeName}</span>
      </Modal.Header>
      <Modal.Body>
        <div>
          {query.isLoading && <div>Loading recipe details...</div>}
          {query.isError && <div>Error loading recipe details...</div>}
          {query.data && <RecipeDetails recipe={query.data} />}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function RecipesComponent({
  meal,
  recipes,
}: {
  meal: string;
  recipes: Recipe[];
}) {
  const [recipeDetails, setRecipeDetails] = useState<Recipe | null>(null);

  return (
    <div className="rounded-xl border border-gray-400 px-5 py-3">
      {recipeDetails && (
        <RecipeDetailsModal
          recipeId={recipeDetails.id}
          recipeName={recipeDetails.name}
          onClose={() => setRecipeDetails(null)}
        />
      )}
      <h4 className="font-semibold text-gray-800">{meal}</h4>
      <div className="flex flex-col gap-2 mt-2">
        {recipes.map((recipe) => (
          <PaywallButton
            enablePaywall={true}
            key={recipe.id}
            onClick={() => setRecipeDetails(recipe)}
          >
            <div className="text-left text-sm text-blue-500 font-semibold">
              <PaywallInlineIcon color="#2196F3" suffix=" " />
              {recipe.name}
            </div>
          </PaywallButton>
        ))}
      </div>
    </div>
  );
}

export function NutritionRecommendationComponent() {
  const nutritionQuery = useGetNutritionRecommendationQuery();
  const regenerateMenuMutation = useRegenerateMenuMutation();
  const data = nutritionQuery.data;

  if (nutritionQuery.isError) {
    return <div>Error loading nutrition recommendation...</div>;
  }

  if (nutritionQuery.isLoading || !data) {
    return (
      <div className="font-semibold text-gray-600 text-center flex flex-col justify-center mt-4">
        <p>Generating your personalized nutrition plan...</p>
        <p className="text-sm">(this may take up to 2 minutes)</p>
        <p className="text-7xl mt-6">🐌</p>
      </div>
    );
  }

  return (
    <>
      <div>
        {/* {config.GOD_MODE && (
          <div className="mt-4 mb-4">
            {!regenerateMenuMutation.isPending && (
              <button
                type="button"
                onClick={() => regenerateMenuMutation.mutate()}
              >
                Regenerate menu
              </button>
            )}
            {regenerateMenuMutation.isPending && (
              <div>Regenerating menu...</div>
            )}
          </div>
        )} */}
        {data.justification && (
          <div className="text-[13px] text-gray-600 mt-3 mb-4">
            ⓘ {data.justification}
          </div>
        )}
        <div className="flex flex-col gap-2 mt-2">
          {data.menu.breakfast.length > 0 && (
            <RecipesComponent meal="Breakfast" recipes={data.menu.breakfast} />
          )}
          {data.menu.lunch.length > 0 && (
            <RecipesComponent meal="Lunch" recipes={data.menu.lunch} />
          )}
          {data.menu.dinner.length > 0 && (
            <RecipesComponent meal="Dinner" recipes={data.menu.dinner} />
          )}
          {data.menu.snack.length > 0 && (
            <RecipesComponent meal="Snacks" recipes={data.menu.snack} />
          )}
        </div>
      </div>
    </>
  );
}

export function NutritionComponent({ user }: { user: User }) {
  const articles = user.articles.filter((article) =>
    article.metadata.tags.includes("nutrition"),
  );
  return (
    <>
      {articles.map((article) => (
        <ArticleButtonWithBorderAndCheckmark
          key={article.metadata.id}
          article={article}
        />
      ))}
      <h4 className="text-lg font-semibold text-gray-600 text-xs mt-10">
        PERSONALIZED MEAL SUGGESTIONS
      </h4>
      <NutritionRecommendationComponent />
    </>
  );
}
