import React, { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { usePostHog } from "posthog-js/react";
import { User } from "./types";
import MenuBar from "./components/MenuBar";

/* Layout when logged in */
function AppLayout({ user, children }: { user: User; children: ReactNode }) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const posthog = usePostHog();

  useEffect(() => {
    posthog.identify(user.id, { email: user.email });
  }, [user.id, user.email]);

  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (pathname == "/chat" && eventData.dir == "Left") navigate("/plan");
      if (pathname == "/plan" && eventData.dir == "Left") navigate("/food");
      if (pathname == "/plan" && eventData.dir == "Right") navigate("/chat");
      if (pathname == "/food" && eventData.dir == "Left") navigate("/profile");
      if (pathname == "/food" && eventData.dir == "Right") navigate("/plan");
      if (pathname == "/profile" && eventData.dir == "Right") navigate("/food");
    },
    delta: 60,
    swipeDuration: 350,
  });

  return (
    <div className="flex flex-col h-screen" {...swipeHandlers}>
      <MenuBar user={user} />
      {children}
    </div>
  );
}

export default AppLayout;
