import { hasUserReadArticle, User } from "../../types";
import { LibraryArticleButton } from "../../components/ArticleComponent";

function LibrarySection({ user }: { user: User }) {
  const completedArticles = user.articles.filter((article) =>
    hasUserReadArticle(article.state),
  );
  return (
    <>
      {completedArticles.length > 0 && (
        <div>
          <h4 className="text-lg font-semibold mb-4">Your library</h4>
          <div className="flex gap-4 items-stretch">
            {completedArticles.map((article) => (
              <LibraryArticleButton
                key={article.metadata.id}
                article={article}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default LibrarySection;
