import { User } from "./types";

export function weekDoneTaskCount(user: User) {
  if (!user.weeklyPlan) {
    return 0;
  }
  let count = 0;
  for (const day of Object.values(user.weeklyPlan.days)) {
    if (day.tasks.length > 0 && day.done) {
      count++;
    }
  }
  return count;
}

export function weekTotalTaskCount(user: User) {
  if (!user.weeklyPlan) {
    return 0;
  }
  let count = 0;
  for (const day of Object.values(user.weeklyPlan.days)) {
    if (day.tasks.length > 0) {
      count++;
    }
  }
  return count;
}

export function weekStreakRequiredTaskCount(user: User) {
  return Math.ceil(weekTotalTaskCount(user) / 2);
}

export function weekStreakRemainingTaskCount(user: User) {
  return Math.max(
    weekStreakRequiredTaskCount(user) - weekDoneTaskCount(user),
    0,
  );
}

export function isThisWeekStreakRequirementMet(user: User) {
  return weekDoneTaskCount(user) >= weekStreakRequiredTaskCount(user);
}

export function currentWeekStreak(user: User) {
  const completedThisWeekStreak =
    weekDoneTaskCount(user) >= weekStreakRequiredTaskCount(user);
  return user.previousWeeksStreak + (completedThisWeekStreak ? 1 : 0);
}

export function streakProgressTitleAndText(
  user: User,
  isRewardScreen: boolean,
) {
  const requiredCount = weekStreakRequiredTaskCount(user);
  const doneCount = weekDoneTaskCount(user);
  const remainingCount = weekStreakRemainingTaskCount(user);

  let title = "Nothing to do";
  let text = "You have no tasks. This week streak is on us!";
  if (requiredCount > 0) {
    if (remainingCount == 0) {
      if (isRewardScreen) {
        const streak = currentWeekStreak(user);
        title = "Fantastic!";
        text = `You’ve completed your ${streak} weeks streak. Stay committed to keep your results.`;
      } else {
        title = "Completed!";
        text = "No tasks left to complete streak this week";
      }
    } else if (remainingCount == 1) {
      title = "Almost there!";
      text = "1 task left to complete streak this week";
    } else if (doneCount == 0) {
      title = "Time to begin";
      text = `${requiredCount} tasks left to complete streak this week`;
    } else if (doneCount == 1) {
      title = "Great start!";
      text = `${remainingCount} tasks left to complete streak this week`;
    } else {
      title = "On the right way";
      text = `${remainingCount} tasks left to complete streak this week`;
    }
  }

  return [title, text];
}
