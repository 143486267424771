import React, { ReactNode } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

export function SelectNumberField({
  id,
  label,
  value,
  setValue,
  min,
  max,
  unit,
}: {
  id: string;
  label?: string;
  value: number;
  setValue: (v: number) => void;
  min: number;
  max: number;
  unit?: string;
}) {
  var options: any = [];

  for (let i = min; i < max + 1; i = i + 1) {
    options.push({ value: i });
  }

  return (
    <div className="flex flex-col gap-2">
      {label && <label htmlFor={id}>{label}</label>}
      <select
        className="p-2 rounded-lg border border-gray-200 bg-white"
        name={id}
        id={id}
        value={value}
        onChange={(ev) => setValue(parseFloat(ev.target.value))}
      >
        {options.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.value} {unit ? unit : undefined}
          </option>
        ))}
      </select>
    </div>
  );
}

function enumerateFeetAndInches(
  startFeet: number,
  startInches: number,
  endFeet: number,
  endInches: number,
) {
  let result = [];

  for (let feet = startFeet; feet <= endFeet; feet++) {
    let start = feet === startFeet ? startInches : 0;
    let end = feet === endFeet ? endInches : 11; // Up to 11 because we count from 0 to 11 to make 12 inches

    for (let inch = start; inch <= end; inch++) {
      result.push(`${feet}ft${inch}''`);
    }
  }
  return result;
}

const feets = enumerateFeetAndInches(3, 9, 7, 3);

export function SelectFeetHeightField({
  id,
  label,
  value,
  setValue,
}: {
  id: string;
  label?: string;
  value: string;
  setValue: (v: string) => void;
}) {
  let options = feets.map((feet) => ({ value: feet, label: feet }));

  return (
    <SelectTextField
      id={id}
      label={label}
      value={value}
      setValue={setValue}
      options={options}
    />
  );
}

export function SelectWeightKgField({
  id,
  label,
  value,
  setValue,
}: {
  id: string;
  label?: string;
  value: number;
  setValue: (v: number) => void;
}) {
  return (
    <SelectNumberField
      id={id}
      label={label}
      value={value}
      setValue={setValue}
      min={30}
      max={140}
      unit={"kg"}
    />
  );
}
export function SelectWeightLbsField({
  id,
  label,
  value,
  setValue,
}: {
  id: string;
  label?: string;
  value: number;
  setValue: (v: number) => void;
}) {
  return (
    <SelectNumberField
      id={id}
      label={label}
      value={value}
      setValue={setValue}
      min={66}
      max={309}
      unit={"lbs"}
    />
  );
}

export function SelectTextField({
  id,
  label,
  value,
  setValue,
  options,
}: {
  id: string;
  label?: string;
  value: string;
  setValue: (v: string) => void;
  options: { label: string; value: string }[];
}) {
  return (
    <div className="flex flex-col gap-2">
      {label && <label htmlFor={id}>{label}</label>}
      <select
        className="p-2 rounded-lg border border-gray-200 bg-white"
        name={id}
        id={id}
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
      >
        {options.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export function TextAreaField({
  id,
  label,
  value,
  setValue,
  placeholder,
}: {
  id: string;
  label?: string;
  value: string;
  setValue: (v: string) => void;
  placeholder: string;
}) {
  return (
    <div className="flex flex-col gap-2">
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        id={id}
        name={id}
        className="resize-none p-2 rounded-lg border border-gray-200 bg-white"
        onChange={(ev) => setValue(ev.target.value)}
        value={value}
        placeholder={placeholder}
        rows={1}
      />
    </div>
  );
}

export function TextareaField({
  id,
  label,
  value,
  setValue,
  placeholder,
  rows,
}: {
  id: string;
  label?: string;
  value: string;
  setValue: (v: string) => void;
  placeholder: string;
  rows?: number;
}) {
  if (!rows) rows = 6;
  return (
    <div className="flex flex-col gap-2">
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        id={id}
        name={id}
        className="resize-none p-2 rounded-lg border border-gray-200 bg-white"
        onChange={(ev) => setValue(ev.target.value)}
        value={value}
        placeholder={placeholder}
        rows={rows}
      />
    </div>
  );
}

export function PhoneInputField({
  id,
  label,
  value,
  setValue,
}: {
  id: string;
  label?: string | ReactNode;
  value: string;
  setValue: (v: string) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      {label && <label htmlFor={id}>{label}</label>}
      <PhoneInput
        defaultCountry="us"
        value={value}
        onChange={(n: string) => setValue(n)}
        inputClassName="w-full rounded-e-lg"
      />
    </div>
  );
}
