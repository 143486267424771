import React from "react";

function SubscriptionStartedPage() {
  return (
    <div>
      <div>Thank you for signing up!</div>
      <a className="text-blue-500" href="/chat">
        Back to your program
      </a>
    </div>
  );
}

export default SubscriptionStartedPage;
