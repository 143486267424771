import { useEffect, useState, useRef } from "react";
import AppLayout from "../../AppLayout";
import { useGetMe } from "../../queries";
import { User, WeeklyPlan, DAYS, isUserSubscribed } from "../../types";
import { WeeklyProgram } from "../../components/WeeklyProgram";
import { config } from "../../Constants";
import { Link } from "react-router-dom";
import { Crown, Fire, Streak15 } from "../../components/Icons";
import { ProgressModal } from "../../components/ProgressModal";
import { WeekProgressBar } from "../../components/ProgressBar";
import {
  currentWeekStreak,
  isThisWeekStreakRequirementMet,
  weekDoneTaskCount,
  weekTotalTaskCount,
} from "../../week-progress-util";
import { PiPencilSimpleLineFill } from "react-icons/pi";
import { WeekStreakModal } from "../../components/WeekStreakModal";
import { ArticleButtonWithBorderAndCheckmark } from "../../components/ArticleComponent";

function ProgressPercentage({ percentage }: { percentage: number }) {
  return (
    <div className="flex flex-row gap-1 items-center">
      <svg
        aria-hidden="true"
        role="status"
        className="inline w-4 h-4"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="8" fill="white" />
        <path
          d="M5 8L7 10L11 6"
          stroke="#F179B0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="text-white font-semibold">{`${percentage}%`}</div>
    </div>
  );
}

function calculatePlanCompletionPercentage(user: User) {
  const totalCount = weekTotalTaskCount(user);
  const doneCount = weekDoneTaskCount(user);
  if (doneCount >= totalCount) {
    return 100;
  }
  return Math.min(Math.round((doneCount / totalCount) * 100), 100);
}

function PlanHeader({ user }: { user: User }) {
  const completionPercentage = calculatePlanCompletionPercentage(user);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showWeekStreakModal, setShowWeekStreakModal] = useState(false);
  const streak = currentWeekStreak(user);

  return (
    <>
      <ProgressModal
        user={user}
        show={showProgressModal}
        onClose={() => setShowProgressModal(false)}
      />
      <WeekStreakModal
        show={showWeekStreakModal}
        user={user}
        isRewardScreen={false}
        onClose={() => setShowWeekStreakModal(false)}
      />
      <div className="flex flex-col gap-0 text-white border-b border-white bg-gradient-to-r from-[#CD97F9] via-[#EA7CC2] to-[#FF779B]">
        <div className="h-[72px]" />
        <div className="flex flex-col px-4 mb-5 gap-6">
          <div className="flex justify-between font-semibold">
            <button
              type="button"
              className="bg-black bg-opacity-20 rounded-full px-3 py-2"
              onClick={() => setShowProgressModal(true)}
            >
              <div className="flex flex-row items-center gap-1">
                <Fire />
                <div>{user.fires}</div>
              </div>
            </button>
            <button
              type="button"
              className="bg-black bg-opacity-20 rounded-full px-3 py-2"
              onClick={() => setShowProgressModal(true)}
            >
              <div className="flex flex-row items-center gap-1">
                <Crown />
                <div>Level {user.level}</div>
              </div>
            </button>
            <button
              type="button"
              className="bg-black bg-opacity-20 rounded-full px-3 py-2"
              onClick={() => setShowProgressModal(true)}
            >
              <div className="flex flex-row items-center gap-1">
                <div>
                  <Streak15 /> {streak} {streak == 1 ? "week" : "weeks"} streak
                </div>
              </div>
            </button>
          </div>
          <div className="flex flex-col gap-0">
            <div className="flex flex-row justify-between">
              <div className="font-semibold">This Week Progress</div>
              <ProgressPercentage percentage={completionPercentage} />
            </div>
            <WeekProgressBar
              percentage={completionPercentage}
              isWeekStreakRequirementMet={isThisWeekStreakRequirementMet(user)}
              onClick={() => setShowWeekStreakModal(true)}
            />
          </div>
        </div>
        <div className="w-full h-4 bg-white rounded-t-lg"> </div>
      </div>
    </>
  );
}

function ThisWeekProgramSection({
  user,
  weeklyPlan,
}: {
  user: User;
  weeklyPlan: WeeklyPlan | undefined;
}) {
  if (!weeklyPlan) {
    return (
      <div>
        <h4 className="text-lg font-semibold mb-2">This week program</h4>
        <div className="italic">
          Program not yet determined - Work with your coach to define it!
        </div>
      </div>
    );
  }

  const totalDays = DAYS.filter((day) => {
    let entry = (weeklyPlan?.days as any)?.[day];
    return entry ? entry.tasks.length > 0 : false;
  }).length;
  const achievedDays = DAYS.filter((day) => {
    let entry = (weeklyPlan?.days as any)?.[day];
    return entry ? entry.done : false;
  }).length;

  return (
    <div>
      <h4 className="text-lg font-semibold mb-2 flex flex-row justify-between">
        <div>This week</div>
      </h4>
      <div className="p-0">
        <WeeklyProgram user={user} weeklyPlan={weeklyPlan} showStatus={true} />
      </div>
    </div>
  );
}

function PlanComponent({ user }: { user: User }) {
  const hasSubscription: boolean = isUserSubscribed(user);
  const profileSetUp: boolean =
    user.name != null &&
    (user.currentWeightKg != null || user.currentWeightLbs != null);

  const [showWeekStreakRewardModal, setShowWeekStreakRewardModal] =
    useState(false);
  // Used to show the reward screen only if the state of isRewardedThisWeekStreak
  // changes while this component is mounted.
  const weekStreakRewardedPreviousState = useRef(user.isRewardedThisWeekStreak);

  useEffect(() => {
    if (
      !weekStreakRewardedPreviousState.current &&
      user.isRewardedThisWeekStreak &&
      user.previousWeeksStreak === 1
    ) {
      setShowWeekStreakRewardModal(true);
      weekStreakRewardedPreviousState.current = user.isRewardedThisWeekStreak;
    }
  }, [user.isRewardedThisWeekStreak]);

  const exerciseArticles = user.articles.filter(
    (article) => !article.metadata.tags.includes("nutrition"),
  );

  return (
    <div
      className="bg-white flex-grow overflow-y-auto pb-20"
      id="profile_page_content"
    >
      <PlanHeader user={user} />
      <div className="flex flex-col gap-6 px-4 pt-4">
        {!user.weeklyPlan && (
          <div className="font-semibold text-center">
            <p>
              <Link className="text-blue-500 underline" to="/chat">
                Work with your coach
              </Link>
            </p>
            <p>to create your weekly program!</p>
          </div>
        )}
        {user.weeklyPlan && (
          <>
            {exerciseArticles.map((article) => (
              <ArticleButtonWithBorderAndCheckmark
                key={article.metadata.id}
                article={article}
              />
            ))}
          </>
        )}
        {user.weeklyPlan && (
          <ThisWeekProgramSection user={user} weeklyPlan={user.weeklyPlan} />
        )}
        {user.weeklyPlan && hasSubscription && (
          <>
            <WeekStreakModal
              show={showWeekStreakRewardModal}
              user={user}
              isRewardScreen={true}
              onClose={() => setShowWeekStreakRewardModal(false)}
            />

            <div className="flex justify-center items-center h-[44px] rounded-lg w-full bg-[#4989E8]">
              <Link className="font-medium text-white" to="/chat?edit_plan=1">
                <PiPencilSimpleLineFill className="inline mb-[2px]" /> Edit My
                Plan
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function PlanPage() {
  const { data: user, isLoading: userIsLoading } = useGetMe();

  if (userIsLoading) return <></>;

  if (!user) {
    window.location.href = `${config.url.API_URL}/login?page=/plan`;
    return <></>;
  }

  return (
    <AppLayout user={user}>
      <PlanComponent user={user} />
    </AppLayout>
  );
}

export default PlanPage;
