import { useEffect, useRef } from "react";

interface Emoji {
  emoji: string;
  x: number;
  y: number;
  velocityX: number;
  velocityY: number;
  fontSize: number;
  color: string;
}

// Parent must be relative, otherwise centered in the viewport
export function ExplotionAnimation({
  position,
  onEnd,
}: {
  position: string;
  onEnd: () => void;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const emojis = useRef<Array<Emoji>>([]);
  const totalEmojiCount = 40;
  const lastFrameTime = useRef(0);
  const animationStartTime = useRef(0);
  const continueDraw = useRef(true);

  const generateEmojis = (canvas: HTMLCanvasElement): Array<Emoji> => {
    let tempEmojis = [];
    for (let i = 0; i < totalEmojiCount; i++) {
      const fontSize = Math.floor(Math.random() * 10) + 7;
      const speed = Math.floor(Math.random() * 120) + 60;
      const velocityAngle = Math.random() * Math.PI * 2;
      const x =
        canvas.width / 2 -
        fontSize / 4 +
        (Math.cos(velocityAngle) * canvas.width) / 20;
      const y =
        canvas.height / 2 +
        fontSize / 4 +
        (Math.sin(velocityAngle) * canvas.height) / 20;
      tempEmojis.push({
        emoji: "⁕",
        x: x,
        y: y,
        velocityX: Math.cos(velocityAngle) * speed,
        velocityY: Math.sin(velocityAngle) * speed,
        fontSize,
        color: `hsl(${Math.floor(Math.random() * 360)} 50% 50% / 0)`,
      });
    }
    return tempEmojis;
  };

  const calculateAlpha = (t: number): number => {
    t *= 3;
    if (t >= 2) {
      return 0;
    }
    return 0.5 - 0.5 * Math.cos(Math.PI * t);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = 300;
    canvas.height = 300;
    const context = canvas.getContext("2d");
    if (!context) return;
    context?.scale(1, 1);
    emojis.current = generateEmojis(canvas);
    lastFrameTime.current = performance.now();
    animationStartTime.current = performance.now();
    const animation = () => {
      const currentTime = performance.now();
      const deltaTime = Math.max(
        Math.min(currentTime - lastFrameTime.current, 1000),
        0,
      );
      const animationTime = currentTime - animationStartTime.current;
      const alpha = calculateAlpha(animationTime / 1000);
      lastFrameTime.current = currentTime;
      context?.clearRect(0, 0, canvas.width, canvas.height);
      emojis.current.forEach((emoji) => {
        context.font = `${emoji.fontSize}px serif`;
        context.fillStyle = emoji.color;
        context.fillText(emoji.emoji, emoji.x, emoji.y);
        emoji.x += (emoji.velocityX * deltaTime) / 1000;
        emoji.y += (emoji.velocityY * deltaTime) / 1000;
        emoji.color = `hsl(from ${emoji.color} h s l / ${alpha})`;
      });
      if (animationTime > 2000) {
        onEnd();
        continueDraw.current = false;
      } else {
        requestAnimationFrame(animation);
      }
    };
    if (continueDraw.current) requestAnimationFrame(animation);
    return () => onEnd();
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className={`absolute ${position} transform -translate-x-1/2 -translate-y-1/2 w-[300px] h-[300px] z-50 pointer-events-none`}
    />
  );
}
