export function lbsToKg(lbs: number): number {
  return Math.round(lbs * 0.45359237);
}

export function kgToLbs(kg: number): number {
  return Math.round(kg / 0.45359237);
}

//export function cmToFt(cm: number): number {
//  const oneFoot = 30.48;
//  return parseFloat((cm / oneFoot).toFixed(1));
//}

//export function ftToCm(ft: number): number {
//  const oneFoot = 30.48;
//  return parseFloat((ft * oneFoot).toFixed(1));
//}

// To convert between cm and ft, we directly use the firendly string format of feet.
export function cmToFt(cm: number): string {
  const inchesPerCm = 1 / 2.54; // Convert cm to inches
  const totalInches = cm * inchesPerCm;
  const feet = Math.floor(totalInches / 12); // Get the whole feet
  const inches = Math.round(totalInches % 12); // Get the remaining inches

  return `${feet}ft${inches}''`;
}

export function ftToCm(ft: string): number {
  // Parse the feet and inches from the input string
  const matches = ft.match(/(\d+)ft(\d+)''/);
  if (!matches) {
    throw new Error("Invalid format");
  }
  const feet = parseInt(matches[1], 10);
  const inches = parseInt(matches[2], 10);

  // Convert feet and inches to total inches
  const totalInches = feet * 12 + inches;

  // Convert inches to centimeters
  const cmPerInch = 2.54;
  const totalCm = totalInches * cmPerInch;

  return Math.round(totalCm);
}

export function isEmailValid(email: string): boolean {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ) !== null
  );
}
