import React, { useState, useEffect, useRef } from "react";

interface Emoji {
  emoji: string;
  x: number;
  y: number;
  speed: number;
  fontSize: number;
}

function EmojiRain({
  emojiList,
  totalEmojiCount,
  onEnd,
}: {
  emojiList: Array<string>;
  totalEmojiCount: number;
  onEnd: () => void;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const emojis = useRef<Array<Emoji>>([]);
  const lastFrameTime = useRef(0);
  const animationStartTime = useRef(0);
  const continueDraw = useRef(true);

  const generateEmojis = (canvas: HTMLCanvasElement): Array<Emoji> => {
    let tempEmojis = [];
    for (let i = 0; i < totalEmojiCount; i++) {
      const offsetY = Math.abs(Math.floor(Math.random() * 300));
      const fontSize = Math.floor(Math.random() * 40) + 20;
      const x = Math.floor(Math.random() * canvas.width - fontSize / 2);
      tempEmojis.push({
        emoji: emojiList[Math.floor(Math.random() * emojiList.length)],
        x,
        y: canvas.height + offsetY + fontSize,
        speed: Math.floor(Math.random() * 400) + 600,
        fontSize,
      });
    }
    return tempEmojis;
  };

  useEffect(() => {
    if (!continueDraw.current) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const context = canvas.getContext("2d");
    if (!context) return;
    context?.scale(1, 1);
    emojis.current = generateEmojis(canvas);
    lastFrameTime.current = performance.now();
    animationStartTime.current = performance.now();
    const animation = () => {
      const currentTime = performance.now();
      const deltaTime = Math.max(
        Math.min(currentTime - lastFrameTime.current, 1000),
        0,
      );
      lastFrameTime.current = currentTime;
      context?.clearRect(0, 0, canvas.width, canvas.height);
      emojis.current.forEach((emoji) => {
        context.font = `${emoji.fontSize}px serif`;
        context.fillText(emoji.emoji, emoji.x, emoji.y);
        emoji.y -= (emoji.speed * deltaTime) / 1000;
      });
      if (currentTime - animationStartTime.current > 3000) {
        continueDraw.current = false;
        onEnd();
      }
      if (continueDraw.current) requestAnimationFrame(animation);
    };
    if (continueDraw.current) requestAnimationFrame(animation);
    return () => onEnd();
  }, []);

  return (
    <div>
      <canvas
        className="fixed inset-0 z-50 pointer-events-none"
        ref={canvasRef}
      />
    </div>
  );
}

export default EmojiRain;
