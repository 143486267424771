import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { User } from "../types";

function MenuItemUnderline({ color, width }: { color: string; width: string }) {
  return (
    <div className="flex flex-row justify-center mt-1">
      <div className={`h-[3px] ${width} rounded-full ${color}`} />
    </div>
  );
}

function MenuBar({ user }: { user: User }) {
  const location = useLocation();
  const { hash, pathname, search } = location;

  let bgColor = "bg-white";
  let textColor = "text-black";
  let paddingBottom = "pb-5";
  let underlineColor = "bg-black";
  if (pathname == "/plan") {
    bgColor = "bg-gradient-to-r from-[#CD97F9] via-[#EA7CC2] to-[#FF779B]";
    textColor = "text-white";
    paddingBottom = "pb-3";
    underlineColor = "bg-white";
  } else if (pathname == "/profile") {
    bgColor = "bg-[#302f33]";
    textColor = "text-white";
    paddingBottom = "pb-3";
    underlineColor = "bg-white";
  } else if (pathname == "/food") {
    bgColor = "bg-gradient-to-r from-[#006769] to-[#40A578]";
    textColor = "text-white";
    paddingBottom = "pb-3";
    underlineColor = "bg-white";
  }

  return (
    <div
      className={`fixed text-lg top-0 start-0 end-0 z-20 flex flex-row justify-around px-4 pt-5 md:w-1/2 mx-auto font-semibold ${bgColor} ${paddingBottom}`}
    >
      <div
        className={
          `pb-1 text-center ${textColor} ` +
          (pathname == "/chat" ? "" : "text-opacity-70")
        }
      >
        <Link to="/chat">Chat</Link>
        {pathname == "/chat" && (
          <MenuItemUnderline color={underlineColor} width="w-full" />
        )}
      </div>

      <div
        className={
          `pb-1 text-center whitespace-nowrap ${textColor} ` +
          (pathname == "/plan" ? "" : "text-opacity-70")
        }
      >
        <Link to="/plan">Your Program</Link>
        {pathname == "/plan" && (
          <MenuItemUnderline color={underlineColor} width="w-full" />
        )}
      </div>

      <div
        className={
          `pb-1 text-center ${textColor} ` +
          (pathname == "/food" ? "" : "text-opacity-70")
        }
      >
        <Link to="/food">Food</Link>
        {pathname == "/food" && (
          <MenuItemUnderline color={underlineColor} width="w-full" />
        )}
      </div>

      <div
        className={
          `pb-1 text-center ${textColor} ` +
          (pathname == "/profile" ? "" : "text-opacity-70")
        }
      >
        <Link to="/profile">Profile</Link>
        {pathname == "/profile" && (
          <MenuItemUnderline color={underlineColor} width="w-full" />
        )}
      </div>
    </div>
  );
}

export default MenuBar;
