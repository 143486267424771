export function InputField({
  id,
  label,
  value,
  setValue,
  placeholder,
  fieldType,
}: {
  id: string;
  label?: string;
  value: string;
  setValue: (v: string) => void;
  placeholder: string;
  fieldType?: string;
}) {
  return (
    <div className="flex flex-col gap-2">
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={fieldType}
        id={id}
        name={id}
        className="resize-none p-2 rounded-lg border border-gray-200 bg-white"
        onChange={(ev) => setValue(ev.target.value)}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}
