import { Streak19, Streak26 } from "./Icons";

export function ProgressBar({
  percentage,
  backgroundColor,
  progressColor,
}: {
  percentage: number;
  backgroundColor: string;
  progressColor: string;
}) {
  return (
    <div className={`w-full h-3 rounded-full ${backgroundColor}`}>
      <div
        className={`h-full rounded-full ${progressColor}`}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
}

export function WeekProgressBar({
  percentage,
  isWeekStreakRequirementMet,
  onClick,
}: {
  percentage: number;
  isWeekStreakRequirementMet: boolean;
  onClick: () => void;
}) {
  if (percentage === 50) {
    percentage = 57;
  }
  if (percentage === 0) {
    percentage = 4;
  }
  return (
    <button
      type="button"
      className="w-full h-[32px] flex items-center relative"
      onClick={onClick}
    >
      <div className={`w-full h-3 rounded-full bg-gray-50 bg-opacity-40`}>
        <div
          className={`h-full rounded-full bg-white transition-[width]`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      {isWeekStreakRequirementMet && (
        <div className="flex justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[32px] h-[32px] rounded-full bg-gradient-to-b from-[#FFC634] to-[#FF910F]">
          <Streak19 />
        </div>
      )}
      {!isWeekStreakRequirementMet && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Streak26 color="white" />
        </div>
      )}
    </button>
  );
}
